import React, { useState } from 'react'
import DropdownEditbox from '../DropdownEditbox';
import SalaryChecklist from '../SalaryChecklist';
import './SearchPanel.css'
import UKCityList from './UKCities'

const cities = [
  ...new Set(UKCityList.split('\n')
    .filter(item => item.trim() !== '')
    .filter(item => item.split(' ').length === 1)
  )
].sort()


export default ({ searchOptions, handleSubmit, urlKeyword, urlLocation }) => {
  const [keywords, setKeywords] = useState(searchOptions.keywords)
  const [city, setCity] = useState(searchOptions.city)

  const [salaryRangeList, setSalaryRangeList] =
    useState(searchOptions.salaryRangeList)

  const [salaryType, setSalaryType] = useState(searchOptions.salaryType)

  const [maxAge, setMaxAge] = useState(searchOptions.maxAge) // 28 days

  const handleSalaryRangeChange = (e) => {
    const { checked } = e.target

    const from = e.target.getAttribute('from')
    const to = e.target.getAttribute('to')

    setSalaryRangeList(s => {
      if (checked) {

        // Reset ranges when the salaryType has changed
        if (s.salaryType !== salaryType) {
          s = []
          s.salaryType = salaryType
        }

        const exist = s.find(item => item.from === from && item.to === to)
        if (!exist) {
          s.push({ from, to })
        }
      } else {
        for (let i = 0; i < s.length; ++i) {
          if (s[i].from === from && s[i].to === to) {
            s.splice(i, 1)
            break
          }
        }
      }

      return s
    })
  }

  const handlesalaryTypeChange = (e) => {
    setSalaryType(e.target.value)
  }

  const annumRanges = [
    { from: 0, to: 10000 },
    { from: 10000, to: 20000 },
    { from: 20000, to: 30000 },
    { from: 30000, to: 40000 },
    { from: 40000, to: 50000 },
    { from: 50000, to: 60000 },
    { from: 60000, to: 80000 },
    { from: 80000, to: 100000 },
    { from: 100000, to: 0 },
  ]

  const dayRanges = [
    { from: 0, to: 100 },
    { from: 100, to: 200 },
    { from: 200, to: 300 },
    { from: 300, to: 400 },
    { from: 400, to: 500 },
    { from: 500, to: 600 },
    { from: 600, to: 700 },
    { from: 700, to: 800 },
    { from: 800, to: 900 },
    { from: 900, to: 1000 },
    { from: 1000, to: 0 },
  ]

  const hourRanges = [
    { from: 0, to: 5 },
    { from: 6, to: 10 },
    { from: 11, to: 15 },
    { from: 16, to: 20 },
    { from: 21, to: 25 },
    { from: 26, to: 30 },
    { from: 31, to: 35 },
    { from: 36, to: 40 },
    { from: 41, to: 45 },
    { from: 46, to: 50 },
    { from: 51, to: 0 },
  ]

  return <>
    <div className="row no-gutters search-panel pr-4">
      <div className="col-12">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit &&
              handleSubmit(salaryType, salaryRangeList, maxAge, city, keywords)
          }}
        >
          <div className="row no-gutters">
            <div className="col-12 mb-1">Keywords</div>
            <input
              id="keywords"
              type="text"
              aria-label="Keywords"
              value={keywords}
              placeholder="e.g. schools"
              className="keywords-input-box"
              autoComplete="off"
              onChange={(e) => { setKeywords(e.target.value) }}
            />
          </div>
          <div className="row no-gutters mt-3">
            <div className="col-12 mb-1">Location</div>
            <DropdownEditbox
              valid={true}
              items={cities}
              value={city}
              placeholder="e.g. London"
              onChange={(text) => setCity(text)}
            />
          </div>
          <div className="row no-gutters mt-3 align-items-center">
            <label htmlFor="selectAge">Posted in the</label>
            <select name="age"
              className="vacancy-age-select select"
              onBlur={(e) => setMaxAge(e.target.value)}
              onChange={(e) => setMaxAge(e.target.value)}
              id="selectAge"
            >
              <option value="28">Last 28 Days</option>
              <option value="14">Last 14 Days</option>
              <option value="7">Last 7 Days</option>
              <option value="3">Last 3 Days</option>
              <option value="1">Last 24 Hours</option>
            </select>
          </div>
          <div className="row no-gutters mt-3">
            <div className="col-12">
              <div className="search-panel-row-label mb-1"
                style={{ textAlign: 'left' }}>Salary</div>
              <div className="salary-panel">
                <div className="salary-type-selector">
                  <div
                    className={salaryType === 'Annum' ? "salary-type-selected" : ''}
                  >
                    <input
                      aria-label="Salary Type"
                      type="radio"
                      name="salaryType"
                      id="annumSelector"
                      className="salary-type-radio"
                      defaultChecked={salaryType === 'Annum'}
                      value="Annum"
                      onChange={handlesalaryTypeChange}
                    />
                    <label htmlFor="annumSelector">Annum</label>
                  </div>
                  <div
                    className={salaryType === 'Day' ? "salary-type-selected" : ''}
                  >
                    <input
                      aria-label="Salary Type"
                      type="radio"
                      name="salaryType"
                      id="daySelector"
                      className="salary-type-radio"
                      defaultChecked={salaryType === 'Day'}
                      value="Day"
                      onChange={handlesalaryTypeChange}
                    />
                    <label htmlFor="daySelector">Day</label>
                  </div>
                  <div
                    className={salaryType === 'Hour' ? "salary-type-selected" : ''}
                  >

                    <input
                      aria-label="Salary Type"
                      type="radio"
                      name="salaryType"
                      id="hourSelector"
                      className="salary-type-radio"
                      defaultChecked={salaryType === 'Hour'}
                      value="Hour"
                      onChange={handlesalaryTypeChange}
                    />
                    <label htmlFor="hourSelector">Hour</label>
                  </div>
                </div>

                {salaryType === 'Annum' &&
                  <div className="salary-checklist">
                    <SalaryChecklist
                      ranges={annumRanges}
                      selected={annumRanges.map(item =>
                        undefined !== salaryRangeList.find(salary =>
                          +salary.from === +item.from &&
                          +salary.to === +item.to
                        )
                      )}
                      onChange={handleSalaryRangeChange}
                    />
                  </div>
                }
                {salaryType === 'Day' &&
                  <div className="salary-checklist">
                    <SalaryChecklist
                      ranges={dayRanges}
                      selected={dayRanges.map(item =>
                        undefined !== salaryRangeList.find(salary =>
                          +salary.from === +item.from &&
                          +salary.to === +item.to
                        )
                      )}
                      onChange={handleSalaryRangeChange}
                    />
                  </div>
                }
                {salaryType === 'Hour' &&
                  <div className="salary-checklist">
                    <SalaryChecklist
                      ranges={hourRanges}
                      selected={hourRanges.map(item =>
                        undefined !== salaryRangeList.find(salary =>
                          +salary.from === +item.from &&
                          +salary.to === +item.to
                        )
                      )}
                      onChange={handleSalaryRangeChange}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 text-right pt-2">
              <button
                type="submit"
                className="update-results-submit-button btn-block"
              >
                Update Results
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </>
}
