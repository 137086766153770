import React, { useState, useEffect } from 'react'
import './DropdownEditbox.css'

const maxDroplistItems = 5
const minDroplistActivationTextLength = 3

export default ({ value, valid, items, placeholder, onChange }) => {
  const [text, setText] = useState(value || '')

  const [enableDropList, setEnableDropList] = useState(false)

  useEffect(() => {
    onChange && (value !== text) && onChange(text) && setEnableDropList(true);
  }, [text, onChange, value])

  let similarItems = []

  const showDroplist =
    enableDropList && text && text.length >= minDroplistActivationTextLength &&
    items && !!items.length

  if (showDroplist) {
    similarItems = items.filter(item => {
      const itemText = item.toUpperCase()
      const normalizedText = text.toUpperCase()
      return itemText.search(normalizedText) !== -1
    })
  }

  similarItems.sort((a, b) => {
    const aIndex = a.toUpperCase().search(text.toUpperCase())
    const bIndex = b.toUpperCase().search(text.toUpperCase())
    return aIndex - bIndex
  })

  if (similarItems.length > maxDroplistItems) {
    similarItems.length = maxDroplistItems
  }

  const [selected, setSelected] = useState(0)

  function chooseItem(selected) {
    setText(similarItems[selected])
    setSelected(0)
    setEnableDropList(false)
    similarItems = []
  }

  function handleKeyEvent(e) {
    switch (e.key) {
      case "ArrowDown":
        if (similarItems.length) {
          e.preventDefault()
        }
        if (selected < similarItems.length) {
          setSelected(selected + 1)
        }
        break
      case "ArrowUp":
        if (similarItems.length) {
          e.preventDefault()
        }
        if (selected > 0) {
          setSelected(selected - 1)
        }
        break
      case "Enter":
        if (selected > 0) {
          chooseItem(selected - 1)
        }
        setEnableDropList(false)
        break
      case "Escape":
        setEnableDropList(false)
        setSelected(0)
        break
      default:
        setSelected(0)
    }
  }

  return <>
    <div className="dropdown-editbox">
      <input type="text"
        aria-label="City Dropwdown"
        onChange={e => {
          setEnableDropList(true)
          setText(e.target.value)
        }}
        onBlur={(e) => {
          setEnableDropList(false)
          setSelected(0)
        }}
        value={text}
        onKeyDown={handleKeyEvent}
        placeholder={placeholder}
        className={valid ? '' : 'invalid'}
        role="listbox"
        tabIndex={0}
      />

      {
        !!similarItems.length && <>
          <div className="dropdown-editbox-list">
            {
              similarItems.map((value, index) => {
                const textpos = value.toUpperCase().search(text.toUpperCase())
                const left = value.slice(0, textpos)
                const highlight = value.slice(textpos, textpos + text.length);
                const right = value.slice(textpos + text.length, value.length)

                return <div
                  role="switch"
                  tabIndex={0}
                  aria-checked={(selected === index + 1)}
                  className={(selected === index + 1)
                    ? "dropdown-editbox-list-item dropdown-editbox-selected"
                    : "dropdown-editbox-list-item"}
                  key={value}
                  onMouseDown={() => chooseItem(index)}
                >{left}<span>{highlight}</span>{right}</div>
              })
            }
          </div>
        </>
      }
    </div>
  </>
}
