import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import { useNavigate } from '@reach/router'
import { Link, graphql, useStaticQuery } from 'gatsby'
import jobsSplashBg from 'src/images/splash-bg.jpg'

import './JobSearchMain.css'

import DropdownEditbox from '../DropdownEditbox'
import UKCityList from '../SearchPanel/UKCities'

const cities = [
  ...new Set(
    UKCityList.split('\n')
      .filter((item) => item.trim() !== '')
      .filter((item) => item.split(' ').length === 1),
  ),
].sort()

const JobsByPredefinedList = ({ linkPrefix, title, terms }) => {
  const totalColumns = 4

  const toURL = (term) =>
    linkPrefix +
    term
      .split(' ')
      .map((word) => word.toLowerCase())
      .join('-')

  const ListColumn = ({ items }) => (
    <>
      <div className={`col-sm-6 col-md-${12 / totalColumns} ml-3 ml-md-0`}>
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <Link className="orange-link" to={toURL(item)}>
                {item}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  )

  const columnList = []

  for (let i = 0; i < terms.length; ++i) {
    const columnIndex = i % 4
    columnList[columnIndex] = columnList[columnIndex]
      ? columnList[columnIndex]
      : []
    columnList[columnIndex].push(terms[i])
  }

  return (
    <div className="container">
      <div className="mt-5 mb-5">
        <div>
          <h5>{title}</h5>
        </div>
        <div className="row search-links">
          {columnList.map((items, index) => (
            <ListColumn key={index} items={items} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default () => {
  const [city, setCity] = useState('')
  const [keywords, setKeywords] = useState('')

  const keywordList = keywords
    .split(' ')
    .map((word) => word.trim().toLowerCase())
    .filter((word) => word.trim().length)

  const keywordString = keywordList.length ? keywordList.join('-') : 'jobs-in'

  const submitTargetLink = `/uk/jobs/${keywordString}/${city}`

  const navigate = useNavigate()

  const data = useStaticQuery(graphql`
    {
      datoCmsJobSearchMainPageList {
        byIndustry
        byLocation
      }
    }
  `)

  const searchByIndustryTerms = data?.datoCmsJobSearchMainPageList?.byIndustry.split(
    '\n',
  )
  const searchByLocationTerms = data?.datoCmsJobSearchMainPageList?.byLocation.split(
    '\n',
  )

  return (
    <div className="job-search-main-page">
      <div
        className="searchbar-section"
        style={{
          backgroundImage: `url(${jobsSplashBg})`,
        }}
      >
        <div className="container">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              navigate(submitTargetLink)
            }}
          >
            <div className="searchbar-heading">
              <h1>Search Jobs</h1>
              <div className="under-heading-line" />
            </div>
            <Row>
              <Col sm={4} className="mt-2">
                <label htmlFor="keywords">Keywords</label>
                <input
                  id="keywords"
                  type="text"
                  aria-label="Keywords"
                  value={keywords}
                  placeholder="e.g. schools"
                  className="input-keywords"
                  autoComplete="off"
                  onChange={(e) => {
                    setKeywords(e.target.value)
                  }}
                />
              </Col>
              <Col sm={4} className="mt-2">
                <label htmlFor="keywords">Location</label>
                <DropdownEditbox
                  valid
                  items={cities}
                  value={city}
                  placeholder="e.g. London"
                  onChange={(text) => setCity(text)}
                />
              </Col>
              <Col sm={2} className="mt-2 mt-auto">
                <button className="submit-button btn-block">Search Jobs</button>
              </Col>
            </Row>
          </form>
        </div>
      </div>

      <JobsByPredefinedList
        title="By Industry"
        terms={searchByIndustryTerms}
        linkPrefix="/uk/jobs/"
      />

      <JobsByPredefinedList
        title="By Location"
        terms={searchByLocationTerms}
        linkPrefix="/uk/jobs/jobs-in/"
      />
    </div>
  )
}
