import React from 'react'
import { Link } from '@reach/router'

import './Pagination.css'

const PagingButton = ({ title, to, customClass }) => <>
  <li className={`pagination-button noselect ${customClass || ''}`}>
    {to ? <Link to={to}>{title}</Link> : <span>{title}</span>}
  </li>
</>


export default ({ numberOfButtons, totalPages, currentPage }) => {
  let buttons = []
  const startPage = Math.min(
    Math.max(currentPage - Math.floor(numberOfButtons / 2), 1),
    Math.max(totalPages - numberOfButtons + 1, 1)
  )
  const endPage = Math.min(startPage + numberOfButtons, totalPages + 1)
  for (let i = startPage; i < endPage; ++i) {
    const isCurrentPage = i === +currentPage
    buttons.push(
      <PagingButton
        key={i}
        title={i}
        to={isCurrentPage ? '' : `?page=${i}`}
        customClass={isCurrentPage ? 'pagination-button-active' : ''}
      />)
  }

  // console.log('End page:', endPage, numberOfButtons)
  const visiblePrevButton = +currentPage > 1
  const visibleNextButton = +currentPage < totalPages

  const prevBtnStyle = visiblePrevButton ? '' : 'pagination-button-hidden'
  const nextBtnStyle = visibleNextButton ? '' : 'pagination-button-hidden'

  const prevLink = visiblePrevButton ? `?page=${+currentPage - 1}` : ''
  const nextLink = visibleNextButton ? `?page=${+currentPage + 1}` : ''

  return totalPages > 1 ? <>
    <ul className="pagination-control">
      <PagingButton
        title='<'
        to={prevLink}
        customClass={`pagination-button-back ${prevBtnStyle}`}
      />
      {buttons}
      <PagingButton
        title='>'
        to={nextLink}
        customClass={`pagination-button-forward ${nextBtnStyle}`}
        style={nextBtnStyle}
      />
    </ul>
  </>
    : <></>
}
