import React, { useEffect, useState } from 'react'
import { Router, useNavigate, useLocation } from "@reach/router"
import { Base64 } from 'js-base64'
import dayjs from 'dayjs'
import searchingPreloader from '../images/searching.gif'
import JobItem from '../components/JobSearch/JobItem'
import SearchHeader from '../components/JobSearch/SearchHeader'
import SearchPanel from '../components/JobSearch/SearchPanel'
import JobResultPaging from '../components/JobSearch/Pagination'
import JobSearchMainPage from '../components/JobSearch/MainPage'
import Layout from '../components/Layout'
import PageHelmet from '../components/PageHelmet'

const Hits = ({ hits, keywords }) =>
  hits.map((item) => <JobItem data={item} key={item._id} keywords={keywords} />)

const useQuery = () => new URLSearchParams(useLocation().search)

const JobSearchStartPage = () => {
  const location = useLocation()

  return (
    <Layout
      location={location}
      pageContext={{ layout: { fullwidthLayout: true } }}
    >
      <PageHelmet
        {...{
          title: 'Search UK Jobs',
          description: 'Search UK Jobs',
          url: `https://www.wikijob.co.uk${location.pathname}`,
        }}
      />
      <JobSearchMainPage />
    </Layout>
  )
}

function JobSearch({ searchKeyword, searchLocation, page }) {
  const [currentPage, setCurrentPage] = useState(page ? +page : 1)

  useEffect(() => {
    setCurrentPage(page ? +page : 1)
  }, [page])

  const searchKeywordList =
    searchKeyword && searchKeyword.toLowerCase() !== 'jobs-in'
      ? searchKeyword.split('-')
      : []

  const [query, setQuery] = useState('')
  const [hits, setHits] = useState(null)
  const [matched, setMatched] = useState('')
  const [keywords, setKeywords] = useState(new Set(searchKeywordList))
  const [maxResultsPerPage] = useState(100)
  const [sortBy, setSortBy] = useState('recent')

  const startFrom = (currentPage - 1) * maxResultsPerPage

  const [searchOptions, setSearchOptions] = useState({
    keywords: [...keywords].join(' '),
    city: searchLocation ? searchLocation.split('-').join(' ') : '',
    salaryRangeList: [],
    salaryType: 'Annum',
    maxAge: 28,
  })

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const {
      salaryType,
      salaryRangeList,
      maxAge,
      city,
      keywords,
    } = searchOptions

    if (keywords.length || city.length) {
      const query = composeQuery(
        salaryType,
        salaryRangeList,
        maxAge,
        city,
        keywords,
        maxResultsPerPage,
        sortBy,
        startFrom,
      )

      setQuery(query)
    }
  }, [searchKeyword, searchLocation]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async (query) => {
      if (!query) {
        setHits(null)
        return
      }

      try {
        const res = await queryElastic(query)

        if (res.hits.hits.length === 0) {
          navigate(location.pathname, { replace: true })
        } else {
          setHits(res.hits.hits)
          setMatched(res.hits.total.value)
        }
      } catch (e) {
        console.log(e)
      }
    }

    fetchData(query)
  }, [query])

  useEffect(() => {
    const {
      salaryType,
      salaryRangeList,
      maxAge,
      city,
      keywords,
    } = searchOptions

    const query = composeQuery(
      salaryType,
      salaryRangeList,
      maxAge,
      city,
      keywords,
      maxResultsPerPage,
      sortBy,
      startFrom,
    )

    setQuery(query)
  }, [currentPage, searchOptions, maxResultsPerPage, startFrom, sortBy])

  const handleSubmit = async (
    salaryType,
    salaryRangeList,
    maxAge,
    city,
    keywords
  ) => {
    const keywordList = keywords.split(' ').map(
      word => word.trim().toLowerCase()).filter(word => word.trim().length)
    setKeywords(new Set(keywordList))

    const keywordString =
      keywordList.length ? keywordList.join('-') : 'jobs-in'

    setSearchOptions({
      salaryType,
      salaryRangeList,
      maxAge,
      city,
      keywords,
    })

    const searchURL = `/uk/jobs/${keywordString}/${city}?page=1`

    navigate(searchURL)
  }

  const handleSortBy = sortBy => setSortBy(sortBy)

  const totalPages = matched ? Math.ceil(matched / maxResultsPerPage) : 0

  let title = ''

  if (!searchKeywordList.length && !searchLocation) {
    title = 'All jobs and vacancies'
  }

  if (searchKeywordList.length) {
    title += `${matched.toLocaleString()} `
    for (const keyword of searchKeywordList) {
      title += keyword[0].toUpperCase()
      title += keyword.substring(1)
      title += ' '
    }
    title += 'jobs'
  }

  if (searchLocation) {
    title += title ? '' : `${matched.toLocaleString()} Jobs`
    title += ` in ${
      searchLocation[0].toUpperCase() + searchLocation.substring(1)
    }`
  }

  const dateNow = new Date()

  const displayingFrom = maxResultsPerPage * (currentPage - 1) + 1
  const displayingTo = Math.min(maxResultsPerPage * currentPage, matched)

  return (
    <Layout location={location} pageContext={{ layout: { exclude: false } }}>
      <PageHelmet
        {...{
          title: `${title} - ${dayjs(dateNow).format('MMMM YYYY')} | WikiJob.co.uk`,
          description: `Search ${title} - ${dayjs(dateNow).format('MMMM YYYY')} | WikiJob.co.uk`,
          url: `https://www.wikijob.co.uk${location.pathname}`,
        }}
      />
      <>
        <div className="search-results">
          <SearchHeader
            from={displayingFrom}
            to={displayingTo}
            total={matched}
            title={title}
            sortBy={sortBy}
            onSortBy={handleSortBy}
          />
          <div className="row no-gutters py-3">
            <div className="col-12 col-sm-8 col-md-4 col-lg-3 mb-5">
              <SearchPanel
                searchOptions={searchOptions}
                handleSubmit={handleSubmit}
              />
            </div>
            <div className="col-12 col-lg-9">
              <div className="row mb-4 pl-3">
                <div className="col-12">
                  <a
                    className="h6"
                    href="https://l.wikijob.co.uk/cv-resume-library"
                    rel="sponsored nofollow"
                    target="_blank"
                  >
                    <strong>Upload your resume</strong>
                  </a>
                  <span className="h6">
                    <strong> - Let employers find you</strong>
                  </span>
                </div>
              </div>
              {(totalPages || undefined) && (
                <JobResultPaging
                  numberOfButtons={5}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
              )}
              {hits === null && (
                <div className="searching-preloader">
                  <img
                    src={searchingPreloader}
                    alt="Searching..."
                    width="300"
                    height="300"
                  />
                </div>
              )}
              {hits && !hits.length && <h4>No results found.</h4>}
              <Hits hits={hits || []} keywords={keywords} />
              {(totalPages || undefined) && (
                <JobResultPaging
                  numberOfButtons={5}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
              )}
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}

const JobSearchTemplate = () => {
  const urlQuery = useQuery()
  const page = urlQuery.get('page')

  return (
    <>
      <Router>
        <JobSearchStartPage exact path="/uk/jobs/" />
        <JobSearch exact path="/uk/jobs/:searchKeyword" page={page} />
        <JobSearch
          exact
          path="/uk/jobs/:searchKeyword/:searchLocation"
          page={page}
        />
      </Router>
    </>
  )
}

export default JobSearchTemplate

function composeQuery(
  salaryType,
  salaryRangeList,
  maxAge,
  city,
  keywords,
  maxHits,
  sortBy,
  from,
) {
  const createSalaryRangeFilter = rangeList => ({
    "bool": {
      "should": salaryRangeList.map(salaryRange => (
        {
          range: {
            salarymin: {
              gte: +salaryRange.from || undefined,
              lte: +salaryRange.to || undefined
            }
          }
        }
      ))
    }
  })

  const createAgeFilter = maxAge => ({
    "range": {
      "date": {
        "gte": `now-${maxAge}d/d`,
        "lt": "now/d"
      }
    }
  })

  const createSortOption = sortBy => [
    sortBy === 'relevant' ? '_score'
      : sortBy === 'recent' ? { 'date': 'desc' }
        : sortBy === 'salary-ascend' ? { 'salarymin': 'asc' }
          : sortBy === 'salary-descend' ? { 'salarymax': 'desc' }
            : '_score'
  ]

  const query = {
    "from": +from,
    "size": maxHits,
    "query": {
      "bool": {
        "must": [],
      }
    }
  }

  const must = query.query.bool.must

  maxAge && must.push(createAgeFilter(maxAge))

  keywords && must.push({ "match": { "description": keywords } })

  city && must.push({ "match": { "city": city } })

  salaryRangeList.length && salaryType &&
    must.push({ "match": { "salary_per": salaryType } })

  salaryRangeList.length && must.push(createSalaryRangeFilter(salaryRangeList))

  if (sortBy) {
    query.sort = createSortOption(sortBy)
  }

  return query
}

// function testQuery() {
//   const testURL = `/eltest/testcors`
//   fetch(testURL, {
//     method: 'GET',
//     mode: 'cors',
//     cache: 'no-cache',
//     credentials: 'same-origin',
//   }).then(response => console.log(response))
// }

async function queryElastic(query) {
  const indexName = 'jobs'
  const username = 'elastic'
  const password = '123456@'

  //testQuery()

  const response = await fetch(`${process.env.URL}/${indexName}/_search`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${Base64.encode(`${username}:${password}`)}`,
    },
    body: JSON.stringify(query),
  })

  return response.json()
}
