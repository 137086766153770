export default `
Droitwich Spa
London
Basildon
Carnforth
Kendal
Tonbridge
Norwich
West Kingsdown
Lyme Regis
St Albans
Lichfield
Upton upon Severn
Stockton-on-Tees
Droitwich
Northampton
Stone
Milton Keynes Village
Wimbledon
Irlam
Paisley
Shefford
Eastbourne
Bexleyheath
Harrogate
Romford
Stalybridge
Oldbury
Inkberrow
Fareham
Worcester
Tewkesbury
Kingston upon Hull
Temple Bar
Paignton
Epsom
Ruskington
Hillingdon
Kingsbury
Maidstone
Hitchin
Chester
Flint
Melchbourne
Guildford
Ipswich
Edinburgh
Sutton Coldfield
Poole
Huddersfield
Haywards Heath
Canterbury
Hastings
Glasgow
Nottingham
Dundee
Whitley Bay
North West London
Golborne
Aylesbury
Hounslow
Gillingham
Wandsworth
Nuneaton
Dewsbury
Rushden
Ealing
Dorrington, Lincolnshire
Thatcham
West Bromwich
Telford
Manchester
Stevenage
Bournemouth
Exeter
Smethwick
Rainham, Greater London
Longbridge
Selsey
Canvey Island
Didsbury
Llanelli
Bloxwich
Bearsted
Barnet
Preston
Netherton, Sefton
Hemel Hempstead
Enfield Town
Edmonton
Hatfield
Hertford
Ware
Keston
Brentwood
Cardiff
Monmouth
Perivale
Birkenhead
Kirkby
Broxbourne
Scunthorpe
Orpington
Bristol
North London, Greater London
Leyland
Waterlooville
Taunton
Andover
Romsey
Abingdon
Bordon
Erdington
Hammersmith
Leeds
Aldershot
Bury St Edmunds
Roundhay
Saffron Walden
Windsor
Waltham Cross
Putney
Sidcup
Islington
South Ockendon
Castleford
St Helens
Wellington
Harlow
Brandesburton
Kegworth
Perth
Melksham
Markfield
Tamworth
Inverness, Highland
Stratford and New Town
Wembley
Sheffield
Redditch
Bourne End
Dartford
Over Whitacre
Royal Tunbridge Wells
Forest Hill
West Thurrock
Chelmsford
Croydon
Teddington
Dagenham
Paddington
Boston
Lincoln
Bardney
Dudley
Sevenoaks
Lancaster
Trowbridge
Ellesmere Port
Oldham
City of London
Sydenham
Chesterfield
West Bridgford
South Milford
Toronto
Bromley
Epping
High Wycombe
Melton Mowbray
Watford
Gravesend
Eastchurch
East Grinstead
Adlington, Cheshire East
East Ham
Surbiton
Potters Bar
York
Rotherham
Southwold
Berkhamsted
Leeds ICD
Barnsley
Claydon
Wymondham, Norfolk
Selby
Spalding
Rhondda
Stamford
Rugby
`

// export default `
// Aberdeen
// Milltimber
// Peterculter
// Aberdeen Airport
// Bridge of Don
// Laurencekirk
// Banchory
// Westhill
// Alford
// Aboyne
// Ballatar
// Strathdon
// Ballindalloch
// Aberlour
// Stonehaven
// Ellon
// Peterhead
// Fraserburgh
// Macduff
// Banff
// Inverurie
// Insch
// Turriff
// Huntly
// St Albans
// Hatfield
// Redbourn
// Sandridge
// Harpenden
// Welwyn
// Welwyn Garden City
// Castle Bromwich
// Chelmsley Wood
// Tees Grove
// Bickenhill
// West Bromwich
// Coleshill
// Wythall
// Alvechurch
// Alcester
// Bidford-on-Avon
// Bromsgrove
// Hurst Green
// Tamworth
// Wigginton
// Studley
// Dorridge
// Tanworth-in-Arden
// Henley-in-Arden
// Redditch
// Bath
// Bruton
// Frome
// Longbridge Deverill
// Westbury
// Trowbridge
// Bradford-on-Avon
// Street
// Yeovil
// Mudford
// Kilmersdon
// Shepton Mallet
// St Cuthbert Out
// Glastonbury
// Castle Cary
// Templecombe
// Wincanton
// Darwen
// Burnley
// Ightenhill
// Salterforth
// Rawtenstall
// Accrington
// Clitheroe
// Newchurch
// Queensbury
// Dewsbury
// Bingley
// Steeton
// Keighley
// Thorlby
// Langcliffe
// Upton
// Swanage
// Wareham
// Colehill
// Ferndown
// Burton
// Ringwood
// New Milton
// Verwood
// Ramsbottom
// Westhoughton
// Horwich
// Brighton
// Peacehaven
// Worthing
// Lancing
// Rustington
// Littlehampton
// Arundel
// Eastbourne
// Westham
// Seaford
// Long Man
// Hailsham
// Shoreham-by-Sea
// Steyning
// Newtimber
// Henfield
// Clayton
// Lewes
// Ringmer
// Newhaven
// Bromley
// Swanley
// Bristol
// Kingswood
// Portbury
// Clevedon
// Weston-Super-Mare
// Hutton
// Winscombe
// Weare
// Cheddar
// Wedmore
// Banwell
// Bitton
// Keynsham
// Bradley Stoke
// Stoke Gifford
// Aust
// Frampton Cotterell
// Yate
// Clutton
// Nempnett Thrubwell
// Long Ashton
// Nailsea
// Yatton
// Dundonald
// Dunmurry
// Holywood
// Bangor
// Donaghadee
// Downpatrick
// Newcastle
// Whiteabbey
// Mill Town
// Londonderry
// Ballycastle
// Portstewart
// Portrush
// Bushmills
// Portadown
// Lurgan
// Fivemiletown
// Carryduff
// Carlisle
// Cliburn
// Catterlen
// Keswick
// Cockermouth
// Workington
// Maryport
// Appleby-in-Westmorland
// Kirkby Stephen
// Muncaster
// Gosforth
// Beckermet
// Egremont
// Cleator Moor
// Arlecdon
// St Bees
// Whitehaven
// Wetheral
// Orton
// Kirklinton
// Waverton
// Brampton
// Alston
// Fulbourn
// Saffron Walden
// Newport
// Great Shelford
// Balsham
// Little Shelford
// Highfields
// Over
// Lode
// Hardwick
// Impington
// Stow cum Quy
// Downham
// Soham
// Woodditton
// Haverhill
// Butetown
// Grangetown
// Rhiwbina
// Pentyrch
// Pentwyn
// Adamsdown
// Garw Valley
// Cynffig
// Maesteg
// Pencoed
// Porthcawl
// Pontypridd
// Llantwit Fardre
// Cymmer
// Tonypandy
// Ystrad
// Treorchy
// Ferndale
// Aberdare
// Penrhiwceiber
// Treharris
// Ely
// Llantwit Major
// Barry
// Penarth
// Cowbridge
// Llanharan
// Bargoed
// Gelligaer
// Chester
// Saltney
// Birkenhead
// Wallasey
// Hoylake
// Shotton
// Flint
// Heswall
// Ellesmere Port
// Mold
// Holywell
// Southminster
// Chelmsford
// Billericay
// Brentwood
// Epping
// Harlow
// Great Baddow
// Sawbridgeworth
// Great Hallingbury
// Bishops Stortford
// Stansted Mountfitchet
// Danbury
// Ingatestone
// Ongar
// Great Dunmow
// Braintree
// Witham
// Heybridge
// Colchester
// Sudbury
// Mistley
// Harwich
// Frinton and Walton
// Clacton-on-Sea
// Little Clacton
// Layer Breton
// Fordham
// Elmstead
// Bures Hamlet
// Sible Hedingham
// Croydon
// Whyteleafe
// Merton
// Canterbury
// Broadstairs
// Margate
// Manston
// Sandwich
// Dover
// Whitfield
// Hawkinge
// Folkestone
// Hythe
// Wingham
// Lower Hardres
// Birchington
// Nuneaton
// Bedworth
// Market Bosworth
// Rugby
// Dunchurch
// Leamington Spa
// Radford Semele
// Warwick
// Wasperton
// Shipston-on-Stour
// Stratford-Upon-Avon
// Ladbroke
// Hawkes End
// Kenilworth
// Atherstone
// Crewe
// Middlewich
// Sandbach
// Congleton
// Bridgemere
// Holmes Chapel
// Nantwich
// Tarporley
// Winsford
// Weaverham
// Northwich
// Dartford
// Swanscombe
// Gravesend
// Meopham Station
// Bexley
// Crayford
// Darenth
// Hartley
// Horton Kirby
// Stone
// Dundee
// Kirkton
// Carnoustie
// Swadlincote
// Overseal
// Branston
// Burton upon Trent
// Mickleover
// Matlock Bath
// Bakewell
// Ripley
// Alfreton
// Belper
// Osmaston
// Hilton
// Ilkeston
// Draycott
// Swarkestone
// Lockington
// Heanor
// Annan
// Gretna
// Dalbeattie
// Kirkcudbright
// Durham
// Chester-le-Street
// North Lodge
// Houghton-le-Spring
// Hetton
// Shadforth
// Esh
// Burnhope
// Stanley
// Skeeby
// Marske
// Cotherstone
// Stanhope
// Lcp Stanhope and Wolsingham Parishes And
// Spennymoor
// Ferryhill
// Halnaby Ave
// Shildon
// Great Aycliffe
// Winton
// Yafforth
// Spennithorne
// Colburn
// Everton
// Tickhill
// Conisbrough
// Airmyn
// Burringham
// Barton-Upon-Humber
// Barrow-Upon-Humber
// Broughton
// Corringham
// Clumber and Hardwick
// Edenthorpe
// Cleethorpes
// Tetney
// Bradley
// Bigby
// Ulceby
// Immingham
// Stallingborough
// Brodsworth
// Owston
// Thorne
// Haxey
// Dorchester
// Sturminster Newton
// Bryanston
// Charminster
// Weymouth
// Portland
// Allington
// Lyme Regis
// Beaminster
// Castleton
// Kidderminster
// Upper Arley
// Stourport-on-Severn
// Cleobury Mortimer
// Kinver
// Poplar
// Walthamstow
// Stratford
// East Ham
// Ilford
// Queen Elizabeth Olympic Park
// Hackney
// Islington
// Camden Town
// Old Town
// Morningside, Braid Hill and Swanston
// Gorgie, Stenhouse and Sighthill
// Murrayfield, Corstorphine and Gogar
// Colinton and Oxgangs
// Juniper Green, Currie and Balerno
// Portobello
// Liberton and Craigmillar
// Moredun
// Bonnyrigg and Lasswade
// New Town
// Loanhead
// Musselburgh
// Dalkeith
// Arniston
// Rosewell
// Roslin
// Penicuik
// Kirknewton
// Newbridge and Ratho
// Kirkliston
// Inverleith, New Town and Fountainbridge
// Queensferry
// Gullane
// Cockenzie and Port Seton
// Tranent
// Pencaitland
// Ormiston
// Humbie
// Pathhead
// Heriot
// North Berwick
// Davidson's Mains, Barnton and Cramond
// East Linton
// Haddington and Gifford
// Dunbar
// Walkerburn
// Innerleithen
// Peebles
// West Linton and Dolphinton
// Blackburn, Whitburn and Fauldhouse
// Bathgate
// Linlithgow
// Granton and Trinity
// Grangepans
// Broxburn
// Livingston
// West Calder
// Newhaven and Leith
// Bonnington, Lochend and Craigentinny
// South Bridge, Holyrood and Willowbrae
// Marchmont and Blackford
// Enfield
// Broxbourne
// Hoddesdon
// Barnet
// Potters Bar
// Waltham Abbey
// Exeter
// Sidmouth
// Ottery St Mary
// Seaton
// Axminster
// Honiton
// Kentisbeare
// Tiverton
// Sandford
// Chawleigh
// Dowland
// Okehampton Hamlets
// Black Torrington
// Holsworthy Hamlets
// Bude
// Colyton
// Barnstaple
// Braunton
// Ilfracombe
// Lynton
// Bishops Nympton
// High Bickington
// Little Torrington
// Abbotsham
// Broad Clyst
// Doddiscombsleigh
// Dawlish
// Exmouth
// Budleigh Salterton
// Alloa
// Menstrie
// Alva
// Tillicoultry
// Doune
// Callander
// Strathyre
// Hope St
// Killin
// Grangemouth
// Dennyloanhead
// Stoneywood
// Bridge of Allan
// Hardhorn and Thornton
// Lytham Saint Annes
// Glasgow
// Thornliebank
// Clydebank
// Milngavie
// Killearn
// Bishopbriggs
// Kilsyth
// Kirkintilloch
// Cumbernauld
// Balloch
// Cambuslang
// East Kilbride
// Newton Mearns
// Dumbarton
// Alexandria
// Helensburgh
// Gloucester
// Stonehouse
// Dursley
// Berkeley
// Littledean
// Lydney
// Coleford
// Drybrook
// Oxenhall
// Ashleworth
// Ashchurch
// Hucclecote
// Rodborough
// Cheltenham
// Prestbury
// Naunton
// Chipping Campden
// Moreton-in-Marsh
// Thrupp
// Ampney St Mary
// Tetbury
// Hawkesbury
// Guildford
// Farnham
// Aldershot
// Ash
// Fleet
// Farnborough
// Camberley
// Blackwater and Hawley
// Windlesham
// Woking
// Bisley
// Virginia Water
// Haslemere
// Tillington
// Midhurst
// Worplesdon
// Liphook
// Petersfield
// Stroud
// Liss
// Alton
// Lindford
// Yateley
// Sandhurst
// Wonersh
// Cranleigh
// Godalming
// Witley
// St Peter Port
// St Sampson
// Carmel
// ALBecq
// L'Islet
// Richmond
// Mouilpied
// nr Mouilpied
// Brent
// Harrow
// Hillingdon
// Huddersfield
// Halifax
// Kirkburton
// Holme
// Harrogate
// Clint
// North Stainley
// Knaresborough
// Hemel Hempstead
// Chepping Wycombe
// High Wycombe
// Bledlow-cum-Saunderton
// Hazlemere
// Great Missenden
// Dinton
// Ashendon
// Aylesbury
// Weston Turville
// Tring
// Princes Risborough
// Berkhamsted
// Chesham
// Amersham
// Chalfont St Giles
// Beaconsfield
// Hampton Bishop
// Allensmore
// Clifford
// Burghill
// Kington Rural
// Leominster
// Bromyard
// Ledbury
// Ross-on-Wye
// Stornoway
// Leverburgh
// Hull
// Cottingham
// Ellerby
// Burstwick
// Hessle
// North Ferriby
// Ellerker
// Hornsea
// Withernsea
// Rowley
// Hebden Royd
// Loughton
// Barking
// Douglas, Braddan
// Onchan
// Peel, German
// Baldrine, Loman
// Kirk Michael
// St Judes, Andreas
// Ramsay
// Arbory
// Ipswich
// Levington
// Felixstowe
// Bromeswell
// Kettleburgh
// Stowupland
// Aldeburgh
// Leiston
// Kelsale
// Southwold
// Halesworth
// Harleston
// Syleham
// Wortham
// Eye
// Thetford
// Ovington
// Methwold
// Brandon
// Tuddenham
// Whepstead
// Hessett
// Pakenham
// Bury St Edmunds
// Kesgrave
// Hemingstone
// Aldham
// Sproughton
// Holbrook
// Inverness
// Cromarty
// Nairn
// Strathpeffer
// Dingwall
// Evanton
// Alness
// Invergordon
// Tain
// Gairloch
// Rogart
// Elgin
// Lossiemouth
// Mosstodloch
// Forres
// Kyleakin
// Saasaig
// Ferrindonald
// Plockton
// Isle of Skye
// Muir of Ord
// Lewiston
// Avoch
// St Helier
// St+F2813 Ouen
// Kilmarnock
// Troon
// Irvine
// Kilwinning
// Glengarnock
// Beith
// Newmilns
// Darvel
// Cumnock
// Gatehead
// Stevenston
// Ardrossan
// West Kilbride
// Dalry
// Kilbirnie
// Millport
// Fairlie
// Largs
// Galston
// Mauchline
// Ayr
// Kingston Upon Thames
// Esher
// Cobham
// West Molesey
// Addlestone
// Epsom
// Reigate and Banstead
// Leatherhead
// Dorking
// East Horsley
// Sutton
// Golspie
// Halkirk
// Thurso
// Kirkwall
// Stromness
// Gorseness
// Whaligoe
// Lybster
// Brora
// Kirkcaldy
// Dunfermline
// Kinross
// Burntisland
// Glenrothes
// Leven
// Kilconquhar
// Aintree
// Garston
// Southport
// Crosby
// Halewood
// Maghull
// Kirkby
// Rainhill
// Hyton
// Ormskirk
// Burscough
// Lancaster
// Sedbergh
// Grange-over-Sands
// Ulverston
// Dalton-in-Furness
// Dalton Town with Newton
// Ireleth
// Kirkby Ireleth
// Millom
// Bootle
// Caton
// Broughton West
// Coniston
// Lakes
// Windermere
// Warton
// Whittingham
// Milnthorpe
// Kendal
// Llandrindod Wells
// Duhonw
// Llanddew
// Llangamarch
// Treflys
// Rhayader
// Knighton
// Presteigne
// Leicester
// Hinckley
// Loughborough
// Melton Mowbray
// Hambleton
// Dingley
// Gilmorton
// Wigston
// Narborough
// Oadby
// Groby
// Ashby-de-la-Zouch
// Coalville
// Barkby
// Kilby
// Gresford
// Abenbury
// Ruabon
// Ruthin
// Denbigh
// St Asaph
// Rhyl
// Prestatyn
// Llangollen
// Corwen
// Betws-yn-Rhos
// Bala
// Bro Machno
// Dolwyddelan
// Llanrwst
// Trefriw
// Mochdre
// Old Colwyn
// Llandudno
// Deganwy
// Henryd
// Llanfairfechan
// Penmaenmawr
// Aberdovey
// Bryncrug
// Llangelynin
// Arthog
// Brithdir
// Ffestiniog
// Barmouth
// Dyffryn Ardudwy
// Llanbedr
// Harlech
// Talsarnau
// Penrhyndeudraeth
// Porthmadog
// Dolbenmaen
// Criccieth
// Buan
// Llanllyfni
// Llanrug
// Y Felinheli
// Llandygai
// Beaumaris
// Cwm Cadnant
// Llanfihangel Ysgeifiog
// Llanidan
// Bodorgan
// Aberffraw
// Rhosneigr
// Valley
// Rhosybol
// Llanbadrig
// Amlwch
// Penysarn
// Moelfre
// Llannerch-Y-Medd
// Llaneugrad
// Llanfair-Mathafarn-Eithaf
// Pentraeth
// Llangefni
// Woodhall Spa
// Louth
// Mablethorpe
// Nettleham
// Fiskerton
// Dunston
// Waddington
// Lincoln
// Caistor
// Linwood
// Horncastle
// Harewood
// Guiseley
// Otley
// Wetherby
// Boston Spa
// Grimston
// Micklefield
// Rothwell
// Gildersome
// Ilkley
// Thorn
// Dunstable
// Leighton-Linslade
// Manchester
// Urmston
// Middleton
// Walkden
// Pemberton
// Partington
// Ashton-under-Lyne
// Ringway
// Rochester
// Sittingbourne
// Queenborough
// Faversham
// Maidstone
// Leeds
// West Farleigh
// West Malling
// Strood
// Ditton
// Hoo St Werburgh
// Snodland
// Gillingham
// Bletchley
// Stony Stratford
// Bradwell
// Stantonbury
// Willen
// Newport Pagnell
// Bow Brickhill
// Buckingham
// Cosgrove
// Shenley Brook End
// Bedford
// Kempston
// Kempston Rural
// Wilden
// Maulden
// Clifton Reynes
// Shenley Church End
// Woughton On The Green
// Walton
// Bradwell Abbey
// Motherwell
// Strathaven
// New Lanark
// Wishaw
// Hamilton
// Coatbridge
// Airdrie
// Carluke
// Larkhall
// Tottenham
// King's Cross
// Wickham and Dunston
// Longbenton
// Brunswick
// Newburn
// Blaydon
// Stamfordham
// Elsdon
// Byker
// Ponteland
// Wansbeck
// Blyth
// Washington
// Wylam
// Prudhoe
// Stocksfield
// Broomhaugh and Riding
// Corbridge
// Hexham
// Allendale
// Bellingham
// Haltwhistle
// Morpeth
// Newton on the Moor
// Denwick
// Ellingham
// North Sunderland
// Bamburgh
// Belford
// Akeld
// Clifton
// Cotgrave
// Bingham
// Lowdham
// Sutton in Ashfield
// Greasley
// Mansfield
// West Bridgford
// Warsop
// Rainworth
// Ompton
// North Muskham
// Newark-on-Trent
// Southwell
// Grantham
// Great Gonerby
// Burton Coggles
// Sleaford
// Carlton
// Beeston
// Northampton
// Rushden
// Badby
// Towcester
// Brackley
// Warkton
// Kettering
// Corby
// Wollaston
// Duston
// Spratton
// Wellingborough
// Irthlingborough
// Graig
// Abercarn
// Blackwood
// Abertillery
// Gwehelog Fawr
// St Arvans
// Caerleon
// Alway
// Allt-yr-yn
// Tredegar
// Ebbw Vale
// New Tredegar
// Monmouth
// Rogiet
// Trevethin
// Cwmbran
// Abergavenny
// Llangattock
// Norwich
// Hevingham
// Erpingham
// Smallburgh
// Blofield
// Alpington
// Hempnall
// Old Buckenham
// Attleborough
// Wymondham
// Scarning
// Swanton Morley
// Fakenham
// Walsingham
// Wells-next-the-Sea
// Briston
// Holt
// Sheringham
// Cromer
// North Walsham
// Great Yarmouth
// Oulton Broad
// Weston
// Bungay
// Taverham
// Marlingford
// Paddington
// Heywood
// Todmorden
// Shaw
// Saddleworth
// Mossley
// Oxford
// Wallingford
// Didcot
// Wantage
// Marcham
// Culham
// Tadmarton
// Banbury
// Middleton Cheney
// Carterton
// Woodstock
// Chesterton
// Bicester
// Fringford
// Witney
// Eynsham
// Holton
// Chinnor
// Cuddesdon
// Watlington
// Kidlington
// Chadlington
// South Leigh
// Thame
// Paisley
// Kilbarchan
// Ranfurly
// Lochwinnoch
// Kilmacolm
// Greenock
// Skelmorlie
// Wemyss Bay
// Rothesay
// Tighnabruaich
// Port Riddell
// Lochgoilhead
// Cairndow
// Strachur
// Ardrishaig
// Bridge of Orchy
// Renfrew
// Port Ellen
// Bowmore
// Blackrock
// Ballygrant
// Port Askaig
// Portnahaven
// Port Charlotte
// Johnstone
// Scalasaig
// Lochdon
// Bunessan
// Bishopton
// Crossapol
// Howwood
// Bourne
// Pinchbeck
// Wisbech
// Emneth
// March
// Chatteris
// St Neots
// Swineshead
// Boston
// Old Leake
// Spilsby
// Burgh Le Marsh
// Skegness
// Ramsey
// St Ives
// The Stukeleys
// Huntingdon
// King's Lynn
// Snettisham
// West Acre
// Shouldham
// Wiggenhall St Germans
// Sandringham
// Old Hunstanton
// Swaffham
// Denver
// Ailsworth
// Deeping St James
// Farcet
// Tansor
// Stamford
// Killichonan
// Dalwhinnie
// Aviemore
// Boat of Garten
// Nethy Bridge
// Grantown-on-Spey
// Auchterarder
// Fort Augustus
// Fort William
// Kentra
// Arisaig
// Blackford
// Mallaig
// Isle of Eigg
// Isle of Rum
// Isle of Canna
// Glencoe
// Muthill
// Kinlochleven
// Comrie
// Crieff
// Rame
// Antony
// Saltash
// Looe
// Liskeard
// South Petherwin
// Lifton
// Callington
// Calstock
// Tavistock
// Horrabridge
// Ivybridge
// Lostwithiel
// Fowey
// St Blaise
// St Austell
// St Mewan
// St Breock
// Padstow
// St Endellion
// Helland
// Bodmin
// Camelford
// St Teath
// Tintagel
// Forrabury and Minster
// Plympton
// Yealmpton
// Plymstock
// Hermitage
// South Hayling
// Gosport
// Wickham
// Funtington
// Chichester
// Sidlesham
// Aldwick
// Felpham
// Cowes
// Nettlestone
// Bembridge
// Sandown
// Shanklin
// Ventnor
// Totland
// Freshwater
// Yarmouth
// Southwick
// Horndean
// Havant
// Preston
// Leyland
// Claughton
// Freckleton
// Bamber Bridge
// Chorley
// Twyford
// Bracknell
// Newbury
// Hungerford
// Bucklebury
// Thatcham
// Basingstoke
// Dummer
// Pamber
// Hook
// Whitchurch
// Odiham
// Warfield
// Wokingham Without
// Woodley
// Earley
// Sulhamstead
// Whitchurch-on-Thames
// Rotherfield Greys
// Crawley
// Horsham
// Southwater
// Wisborough Green
// Burgess Hill
// Haywards Heath
// Cuckfield Rural
// Forest Row
// East Grinstead
// Storrington
// Brockham
// Capel
// Horley
// Lingfield
// Limpsfield
// Godstone
// Romford
// Stapleford Abbotts
// Norton
// Dronfield
// Eckington
// Dinnington
// Todwick
// Grindleford
// Aston
// Ecclesfield
// Hunshelf
// Chesterfield
// Wingerworth
// Staveley
// Bolsover
// Clay Cross
// Rawmarsh
// Bramley
// Brierley
// Darfield
// Hoyland
// Carlton in Lindrick
// Landore
// Blaenhonddan
// Tonna
// Baglan
// Bryn
// Llannon
// Llanelli Rural
// Cefn Sidan
// Kidwelly
// Betws
// Talley
// Sketty
// Llandovery
// Bishopston
// Carmarthen
// Llanegwad
// Meidrim
// Llanfallteg
// Clydau
// Crymych
// Boncath
// Newcastle Emlyn
// Llanfihangel-Ar-Arth
// Gorseinon
// Llanwenog
// Eglwyswrw
// Llangoedmor
// Troedyraur
// New Quay
// Aberaeron
// Llanarth
// Llangybi
// Penderry
// Morriston
// Haverfordwest
// Nolton
// Wiston
// Pencaer
// Fishguard
// Maenclochog
// Narberth
// Jeffreyston
// Saundersfoot
// Llansamlet
// Penally
// Pembroke
// Pembroke Dock
// Milford Haven
// Pontardawe
// Ystradgynlais
// Camberwell
// Greenwich
// Lambeth
// Lewisham
// Eltham
// Stevenage
// Much Hadham
// Standon
// Ware
// Hertford
// Arlesey
// Henlow
// Shefford
// Biggleswade
// Knebworth
// Ippollitts
// Ickleford
// Hitchin
// Bygrave
// Melbourn
// Buntingford
// Macclesfield
// Poynton
// Glossop
// King Sterndale
// New Mills
// Chapel-en-le-Frith
// Cheadle
// Iver
// Colnbrook with Poyle
// Windsor
// Sunninghill
// Maidenhead
// Marlow
// Wooburn
// Chalfont St Peter
// Roundway
// Calne
// Melksham
// Corsham
// Yatton Keynell
// Bremhill
// Malmesbury
// Haydon Wick
// Blunsdon St Andrew
// Wroughton
// Stanton Fitzwarren
// Shellingford
// Savernake
// Manningford
// Longstock
// Winchester
// Bishops Sutton
// Hedge End
// Locks Heath
// Bishops Waltham
// Totton
// Lymington
// Denny Lodge
// Lyndhurst
// Eastleigh
// Romsey
// North Baddesley
// Salisbury
// Andover
// Charlton
// Amesbury
// Downton
// Fordingbridge
// Shaftesbury
// South Tidworth
// Castletown
// Dalton-le-Dale
// Peterlee
// Wickford
// Basildon
// Rochford
// Hockley
// Rayleigh
// Canvey Island
// Forsbrook
// Barlaston
// Leek
// Uttoxeter
// Stafford
// Hopton
// Penkridge
// Gnosall
// Eccleshall
// Newcastle-under-Lyme
// Kidsgrove
// Biddulph
// Bagnall
// Kensington
// Wandsworth
// Hammersmith
// Shrewsbury
// Oswestry Rural
// Oswestry
// Ellesmere
// Whitchurch Rural
// Malpas
// Montgomery
// Llanllwchaiarn
// Caersws
// Llanidloes
// Llanbrynmair
// Glantwymyn
// Castle Caereinion
// Llansantffraid
// Llanfarian
// Geneur Glyn
// Tregaron
// Myddle
// Longden
// Church Stretton
// Hopesay
// Ludlow
// Lydham
// Taunton
// Langport
// Somerton
// Martock
// South Petherton
// Norton Sub Hamdon
// Montacute
// Merriott
// Hinton St George
// Crewkerne
// Ilminster
// Staplegrove
// Chard
// Wellington
// Dulverton
// Williton
// Wootton Courtenay
// Stoke St Mary
// Lydeard St Lawrence
// Spaxton
// Bridgwater
// Stawell
// Burnham-on-Sea
// Burnham Without
// Galashiels
// Greenlaw
// Cornhill-on-Tweed
// Cockburnspath
// Berwick-upon-Tweed
// Earlston
// Newtown St Boswells
// Jedburgh
// Shifnal
// Broseley
// Much Wenlock
// St Georges and Priorslee
// Hollinswood
// Telford
// Rodington
// Madeley
// The Gorge
// Sutton upon Tern
// Royal Tunbridge Wells
// Tonbridge
// Yalding
// Sevenoaks
// Dunton Green
// Wrotham
// Cranbrook
// Four Throws
// Burwash
// Mayfield
// Heathfield
// Uckfield
// Ashford
// Wye
// Shadoxhurst
// Smarden
// New Romney
// St Mary In The Marsh
// Tenterden
// Peasmarsh
// Salehurst
// Battle
// Hastings
// Icklesham
// Bexhill
// Wadhurst
// Crowborough
// Hartfield
// Edenbridge
// Torquay
// South Brent
// Buckfast
// Newton Abbot
// Bovey Tracey
// Teignmouth
// Dartmouth
// Kingsbridge
// Salcombe
// Totnes
// Truro
// Penryn
// Falmouth
// St Keverne
// Helston
// Camborne
// Redruth
// Marazion
// Penzance
// Sancreed
// Tregoney
// Ludgvan
// Isles of Scilly
// Hayle
// Perranarworthal
// Kenwyn
// St Agnes
// Perranporth
// Newquay
// Colan
// St Columb Major
// Redcar
// Saltburn Marske and New Marske
// Skelton
// Loftus
// Guisborough
// Kirklevington
// Egglescliffe
// Thornaby
// Sedgefield
// Trindon
// Billingham
// Monk Hesleden
// Hutton Henry
// Trimdon
// Stainton
// Great and Little Broughton
// Hounslow
// Staines
// Egham
// Southall
// Denham Green
// Ealing
// Birchwood
// Lymm
// Knutsford
// Croft
// Appleton
// Great Sankey
// Frodsham
// Runcorn
// Watford
// Watford Rural
// Chorleywood
// Kings Langley
// Abbots Langley
// Borehamwood
// Radlett
// Mirfield
// Crigglestone
// Normanton
// Featherstone
// North Elmsall
// Leigh
// Worcester
// Wick
// Evesham
// Broadway
// Colwall
// Great Malvern
// Tenbury
// Martley
// North Piddle
// Droitwich
// Cannock
// Lichfield
// Swinfen and Packington
// Brereton
// Willenhall
// Great Wyrley
// Burntwood
// Brownhills
// Aldridge
// Wednesfield
// Bridgnorth
// Chetton
// Wombourne
// Albrighton
// Bilbrook
// Heslington
// Scarborough
// Stainton Dale
// Muston
// Bridlington
// Settrington
// Pickering
// Wheldrake
// Hutton Mulgrave
// Snainton
// Copmanthorpe
// Driffield
// Upper Poppleton
// Rawcliffe
// Earswick
// Catton
// Barmby Moor
// Market Weighton
// Boroughbridge
// Thornton-le-Clay
// Easingwold
// Nawton
// Sowerby
// Selby
// Sound
// Toab
// `;
