import React from 'react'
import './JobItemHead.css'

export default ({ data, jobLinkClass }) => {
  const {
    url,
    title,
    salarymin,
    salarymax,
    salary_per,
    location,
    jobtype,
  } = data

  const salaryMinTitle = salarymin
    ? Math.round(salarymin).toLocaleString('en')
    : ''

  const salaryMaxTitle = salarymax
    ? Math.round(salarymax).toLocaleString('en')
    : ''

  let salaryTitle = 'Not specified'
  if (salaryMinTitle || salaryMaxTitle) {
    if (salaryMinTitle === salaryMaxTitle) {
      salaryTitle = `£${salaryMaxTitle}/${salary_per.toLowerCase()}`
    } else {
      salaryTitle = `£${salaryMinTitle} - £${salaryMaxTitle}/${salary_per.toLowerCase()}`
    }
  }

  return (
    <>
      <div className="job-item-head">
        <a
          href={url}
          target="_blank"
          rel="noreferrer nofollow sponsored"
          className={`job-item-head-title ${jobLinkClass}`}
          aria-label="Job Title"
        >
          <h3 dangerouslySetInnerHTML={{ __html: title }} />
        </a>
        <div className="job-item-head-row">
          <div className="job-item-head-column">
            <span>Salary:</span>
            <span>Location:</span>
            <span>Job Type:</span>
          </div>
          <div className="job-item-head-column">
            <span>{salaryTitle}</span>
            <span>{location}</span>
            <span>{jobtype}</span>
          </div>
        </div>
      </div>
    </>
  )
}
