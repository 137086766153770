import React, { useState } from 'react'
import './JobItem.css'
import JobItemHead from '../JobItemHead'

// Formats post date to "1 day ago", "a week ago", etc.
function formatPostedDate(date) {
  const docDate = new Date(Date.parse(date))
  const postedDaysAgo = (Date.now() - docDate) / (86400 * 1000)

  const getLabel = daysAgo => {
    if (postedDaysAgo < 1) {
      return postedDaysAgo < (1 / 24)
        ? 'Less then an hour ago'
        : `${Math.floor(postedDaysAgo / (1 / 24))} hours ago`
    }

    if (Math.floor(postedDaysAgo) < 2) {
      return `${Math.floor(postedDaysAgo)} day ago`
    }

    if (postedDaysAgo < 7) {
      return `${Math.floor(postedDaysAgo)} days ago`
    }

    if (postedDaysAgo < 14) {
      return 'A week ago'
    }

    if (postedDaysAgo < 21) {
      return 'Two weeks ago'
    }


    const leadingZero = num => num < 10 ? `0${num}` : `${num}`
    const day = leadingZero(docDate.getDay())
    const month = leadingZero(docDate.getMonth())
    const year = `${1900 + docDate.getYear()}`
    return `${day}/${month}/${year}`
  }

  return {
    style: postedDaysAgo < 7 ? 'job-item-post-date-fresh' : '',
    label: getLabel(postedDaysAgo)
  }
}

function heuristicHighlight(text) {
  let lines = text.split('\n')
  lines = lines.map(line => {
    line = line ? line.replace(/(.+:\s)|(.+:)$/g,
      `<span class="job-item-markup-heading">$1</span>`) : line
    line = line ? line.replace(/(\s*\*\s*)(.*)/g,
      `• $2`) : line
    return line
  })

  return lines.join('\n')
}


function renderMarkup(description, keywords) {
  for (const keyword of keywords) {
    const rex = new RegExp(`(${keyword})`, 'gi')
    description = description.replace(rex,
      `<span class='job-description-word-highlight'>$1</span>`)
  }

  description = heuristicHighlight(description)

  return description.replace(/\n/g, '<br>')
}


function HTMLtoText(htmlString) {
  const div = document.createElement('div')
  div.innerHTML = htmlString
  return div.innerText
}

export default ({ data, keywords }) => {
  const source = data._source

  const descriptionText = HTMLtoText(source.description)

  const descriptionWords = descriptionText.split(' ')

  const [maxNumWords, setMaxNumWords] = useState(42)
  const descriptionPart = descriptionWords.slice(0, maxNumWords)
  const cutDescription = descriptionPart.length < descriptionWords.length
  const description = renderMarkup(descriptionPart.join(' '), keywords)

  const { style: postDateCssSelector, label: postDateLabel }
    = formatPostedDate(source.date)

  const jobLinkClass =
    source.url.indexOf('https://www.cv-library.co.uk') === 0
      ? 'jobLink applyLink' : ''

  return source && <>
    <div className="row mb-5">
      <div className="col-12 col-lg-9 order-3 order-lg-1">
        <div className="job-item-main text-justify pl-3 pr-3">
          <JobItemHead data={source} jobLinkClass={jobLinkClass} />
          <span
            dangerouslySetInnerHTML={{
              __html: description + (cutDescription ? '...' : '')
            }}
          />
          {cutDescription &&
            <span className="job-item-main-more orange-link"
              role="button"
              tabIndex={0}
              onKeyDown={e => {
                e.preventDefault();
                setMaxNumWords(descriptionWords.length)
              }}
              onClick={e => {
                e.preventDefault();
                setMaxNumWords(descriptionWords.length)
              }}
            >Expand</span>}
          <a href={source.url} target="_blank"
            rel="noreferrer nofollow sponsored"
            className="job-item-apply-btn applyLink">Apply</a>
        </div>
      </div>
      <div className="col-12 col-lg-3 order-2">
        <div className="row  pl-3 pr-3">
          <a href={source.url} target="_blank"
            rel={
                'noreferrer nofollow sponsored'
            }
            className={jobLinkClass}
          ><img src={source.image} alt="Company Logo"
            style={{ width: '100%' }}
          ></img></a>
        </div>
        <div className="row pl-3 pr-3">
          <div className="col-12">Company:</div>
          <div className="col-12">
            <a
              rel={
                  'noreferrer nofollow sponsored'
              }
              className={`job-item-details-company-link ${jobLinkClass}`}
              href={source.url}>{source.company}</a>
          </div>
        </div>

        <div className="row mt-3  pl-3 pr-3">
          <div className="col-12">Posted:</div>
          <div className={`col-12 ${postDateCssSelector}`}>
            {postDateLabel}
          </div>
        </div>
      </div>
    </div>
  </>
}
