import React from 'react'
import './SearchHeader.css'

export default props => {
  const { from, to, total, sortBy, onSortBy, title } = props

  return (
    <>
      <div className="search-results-heading">
        <h1>{title}</h1>
        <div className="under-heading-line" />
      </div>
      <div className="row no-gutters mb-3">
        <div className="col-xs-12 col-md-3 my-auto">
          {from && to && total ? (
            <>
              Displaying <span>{from}</span> to <span>{to}</span> of{' '}
              <span>{total.toLocaleString('en')}</span> jobs
            </>
          ) : (
            ''
          )}
        </div>
        <div className="col-xs-12 col-md-5 col-lg-auto text-right mt-3 mt-md-0 pr-4 pr-md-0">
          <div className="row no-gutters align-items-center">
            <label htmlFor="sortByFilter">Sort By:</label>
            <select
              className="search-header-sort-by select"
              name="sort-by"
              id="sortByFilter"
              onBlur={e => onSortBy && onSortBy(e.target.value)}
              onChange={e => onSortBy && onSortBy(e.target.value)}
              value={sortBy}
            >
              <option value="relevant">Most Relevant</option>
              <option value="recent">Most Recent</option>
              <option value="salary-descend">Salary (High->Low)</option>
              <option value="salary-ascend">Salary (Low->High)</option>
            </select>
          </div>
        </div>
      </div>
    </>
  )
}
