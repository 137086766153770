import React from 'react'
import './SalaryChecklist.css'

function numberWithCommas(x) {
  return x.toLocaleString('en')
}

const SalaryListItem = ({ from, to, onChange, defaultChecked }) => {
  const inputId = `from${from}to${to}`
  const fromLabel =
    `${from ? '£' + numberWithCommas(from) : 'Up to '}`

  const toLabel = `${to ? '£' + numberWithCommas(to) : '+'}`
  const title =
    `${fromLabel}${(from && to) ? ' - ' : ''}${toLabel}`

  return <>
    <li>
      <input
        aria-label="Range"
        id={inputId}
        type="checkbox"
        from={from}
        to={to}
        onChange={onChange}
        defaultChecked={defaultChecked}
      />
      <label className="noselect" htmlFor={inputId}>{title}</label>
    </li>
  </>
}

export default ({ onChange, ranges, selected }) => <>
  <div className="salary-checklist-container">
    <ul>
      {ranges.map((range, index) =>
        <SalaryListItem
          from={range.from}
          to={range.to}
          onChange={onChange}
          key={`${range.from}${range.to}`}
          defaultChecked={selected[index]}
        />
      )}
    </ul>
  </div>
</>

